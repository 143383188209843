import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"

import Layout from "../../components/layout"
// import SEO from "../../components/seo"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"
import scrollTo from "gatsby-plugin-smoothscroll"

class MiddleSchool extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Middle School"}
          titleDesc={
            "Fuse Classroom, an AI-enabled LMS platform that provides an interactive online learning experience with a variety of features essential for middle schools."
          }
          titleKeywords={
            "Online classes, lms, learning management system, online learning, middle schools, secondary schools, live classes, middle school online class"
          }
          fbDes={"An AI-enabled LMS platform for interactive online learning experience for middle schools."}
          twitDesc={"An AI-enabled LMS platform for interactive online learning experience for middle schools."}
          seoLink={"solutions/middle-school/"}
        />

        {/* top section */}
        <section role="top--section" className="bg-light-green">
          {/* content */}
          <article className="container">
            <Row className="align-items-center py-4">
              <Col md="6" className="pt-md-5 pb-5">
                <div className="text-green">
                  <h1>
                    Fuse Classroom for <br className="d-none d-lg-block" /> Middle Schools
                  </h1>
                  <div className="h4 mt-4 text-black font-weight-normal">
                    Fuse Classroom is an all encompassing platform that leverages interactive online learning to create
                    a new schooling experience for middle schools.
                  </div>

                  <div className="main-cta mt-4">
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-success mr-3 btn-lg font-weight-semibold"
                      // onClick={() => FormEventAnalytics("MiddleSchool_Top_Request_Demo")}
                      state={{ page: "middleschool" }}
                      id="request--for-more-info__top"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                  </div>
                </div>
              </Col>

              <Col sm={{ spans: 5, offset: 1 }}>
                <img
                  src="/images/solutions/middle-school.webp"
                  alt="Education"
                  className="shadow-lg img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* Artificial Intelligence */}
        <section className="pb-5 d-none d-md-block" id="ai-info">
          <article className="container ai-features sm-2">
            <Row>
              <Col sm="12" className="py-4 mb-5 text-center">
                <h2 className="text-center ai-features-title" onClick={() => scrollTo("#AI-Assisted-Learning")}>
                  Artificial <br /> Intelligence
                </h2>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#fuse-classroom")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/classroom.svg" alt="LMS" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">LMS</Card.Title>
                      <div className="small">
                        Jumpstart the digital school experience with our Learning Management System (LMS) and give
                        students a new and exciting way to learn.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#online-live")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/live-classes.svg" alt="Online Live" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Online Live</Card.Title>
                      <div className="small">
                        Conduct online classes in a matter of minutes with our platform that makes scheduling and
                        attending classes easy for teachers and students.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Mobile-App")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/mobile-app.svg"
                          alt="Mobile App"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Mobile App</Card.Title>
                      <div className="small">
                        On-the-go access to on- demand instructional videos, assignments, and live classes.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Transportation-App")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/transportation.svg" alt="Transportation App" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Transportation App</Card.Title>
                      <div className="small">
                        Use this app to manage and track bus schedules and receive notifications in the event of
                        schedule changes or delays.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>
            </Row>
          </article>
        </section>
        {/* Artificial Intelligence End */}

        {/* AI Assisted Learning */}
        <section className="py-5 bg-green" id="AI-Assisted-Learning">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 8, offset: 2 }}>
                <div className="my-4 text-center">
                  <h2 className="my-4 title text-white">AI Assisted Learning</h2>
                  <div className="h4 text-white font-weight-normal">
                    Embedded in Fuse Classroom are AI features including Student Status, Activity Recommendation,
                    Automated Discussion Forums, Question Answering Systems, and a Chatbot Interface. These features
                    help you assess student progress, recommend study material, or automatically answer student
                    questions.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* AI Assisted Learning end */}

        {/* Learning Management System (LMS)*/}
        <section id="fuse-classroom" className="py-5">
          <article className="container py-2">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/solutions/middle/lms-middle.webp"
                  alt="Learning Management System (LMS)"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/classroom.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Learning Management System (LMS)</h2>
                  <div className="h5 text-black font-weight-normal">
                    The LMS platform was designed to help schools create innovative online learning experiences for
                    students and teachers. Powered by AI, the LMS makes it easier than ever to create quizzes,
                    distribute and grade assignments, and provide extensive study materials for students.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* fuse classroom LMS end */}

        {/* Online Live */}
        <section id="online-live" className="py-5 bg-80 left">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/solutions/middle/online-live-middle.webp"
                  alt="Online Live"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/live-classes.svg" alt="Online Live" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Online Live</h2>
                  <div className="h5 text-black font-weight-normal">
                    Instructors are able to host and record live classes directly in the Fuse Classroom platform. Many
                    features are available to facilitate meaningful learning experiences, such as the whiteboard
                    feature, breakout rooms, and chat.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Online Live */}

        {/* Mobile App */}
        <section id="Mobile-App" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/solutions/middle/mobile-app.webp"
                  alt="Mobile App"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/mobile-app.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Mobile App with Parental Controls</h2>
                  <div className="h5 text-black font-weight-normal">
                    The mobile app provides students access to the platform from their mobile device, from on demand
                    videos, assignments, and live classes so students can continue learning wherever they are. Through
                    parental controls access to chat or discussion forums can be restricted.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Mobile App end) */}

        {/* Transportation App */}
        <section className="py-5 bg-80 left" id="Transportation-App">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/solutions/primary/transportation.webp"
                  alt="Transportation App"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/transportation.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Transportation App</h2>
                  <div className="h5 text-black font-weight-normal">
                    The transportation app gives administrators and parents the ability to manage and track bus
                    schedules and notify them in the event of schedule changes or delays.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Transportation App end */}

        {/* call to action */}
        <section className="py-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in more information about our platform?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("MiddleSchool_Bottom_Request_Demo")}
                    state={{ page: "middleschool" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default MiddleSchool
